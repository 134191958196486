import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ZIndexService } from '@garmin-avcloud/avcloud-ui-common/shared';
import { Breakpoints } from '@garmin-avcloud/avcloud-ui-common/style-variables';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { FlyLayoutsModule } from '@garmin-avcloud/avcloud-ui-common/layouts';
import { HeaderComponent } from './subcomponents/header/header.component';
import { SidebarComponent } from './subcomponents/sidebar/sidebar.component';
import { RouterOutlet } from '@angular/router';
import { FlyMessageCenterModule } from '@garmin-avcloud/avcloud-ui-common/message-center';
import { ToFrontOnInteractDirective } from '../../../features/map/directives/to-front-on-interact.directive';

@Component({
  selector: 'pilot-full-template',
  templateUrl: './full-template.component.html',
  styleUrls: ['./full-template.component.scss'],
  imports: [
    FlyLayoutsModule,
    HeaderComponent,
    SidebarComponent,
    RouterOutlet,
    FlyMessageCenterModule,
    ToFrontOnInteractDirective,
  ],
})
export class FullTemplateComponent implements OnInit, OnDestroy {
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly zIndexService = inject(ZIndexService);

  showSidebar = false;
  mobileView = false;
  messageCenterZIndex: number;
  readonly subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscriptions.add(
      this.breakpointObserver
        .observe([Breakpoints.SmallScreen])
        .pipe(distinctUntilChanged())
        .subscribe(({ matches }) => (this.mobileView = matches)),
    );
    this.messageCenterZIndex = this.zIndexService.nextZIndex();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleSidebarVisibility(showSidebar: boolean): void {
    this.showSidebar = showSidebar;
  }
}
