import { DOCUMENT } from '@angular/common';
import {
  Component,
  type OnInit,
  Input,
  type Signal,
  inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { environment } from '@environment';
import { Icons } from '@garmin-avcloud/avcloud-ui-common/icon';

const FEEDBACK_EMAIL = 'gpfeedback@garmin.com';
@Component({
  selector: 'pilot-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() userIsAuthenticated: Signal<boolean>;
  @Output() readonly signIn = new EventEmitter<void>();
  @Output() readonly signOut = new EventEmitter<void>();

  Icons = Icons;

  private readonly document = inject(DOCUMENT);

  ngOnInit(): void {
    this.loadTrustArcScript();
  }

  private loadTrustArcScript(): void {
    const body = this.document.body as HTMLBodyElement;
    const script: HTMLScriptElement = this.document.createElement('script');
    script.src = environment.trustArcConfig.url;
    script.type = 'text/javascript';
    script.crossOrigin = '';

    body.appendChild(script);
  }

  protected openContactUsEmail(): void {
    window.location.href = `mailto:${FEEDBACK_EMAIL}`;
  }
}
